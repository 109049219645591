export let URL = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_LOCAL_URL : (process.env.REACT_APP_ENV === 'staging' ? process.env.REACT_APP_STAGING_URL : (process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : ''));

// export let auth_token = localStorage.getItem('student-token');

export const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  export const auth_token = () => {
    return localStorage.getItem('student-token') 
  }

  export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const paymentOptions = [
    {
      name: "School Fees - Part Payment (₦50,000)",
      value:'50000'
    },
    {
      name: "School Fees - Part Payment Balance (₦7,500)",
      value: 'additional fee part'
    },
    {
      name: "School Fees - Part Payment (₦82,500)",
      value:'82500'
    },
    {
      name: "School Fees - Part Payment (₦100,000)",
      value:'100000'
    },
    {
      name: "School Fees - Full Payment (₦165,000)",
      value:'165000'
    },
    {
      name: "Outstanding Fees",
      value:'additional fee'
    },
    {
      name: "Yet to do Proposal - (₦150,000)",
      value: '150000'
    },
    {
      name: "Proposal done - (₦75,000)",
      value: '75000'
    },
    {
      name: "External defense - (₦100,000)",
      value: 'external defense'
    },
    {
      name: "Convocation/Certificate fee - (₦75,500)",
      value: 'certificate fee'
    },
    
  ]

  export const additionalOptions = [
    {
      name: "Second Installment",
      value:'additional fee'
    },
    
  ]