import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import api from "../../../api/api";
import logo2 from "../../../assets/images/IAUE.png";
import logo from "../../../assets/images/coewarri.png";
import useDetails from "../../../hooks/useDetails";
const CourseRegPrintout = () => {
  const location = useLocation();
  const session = location?.state || "";

  const [validation, setValidation] = useState();
  const [courses, setCourses] = useState();
  const [level, setLevel] = useState("");
  const { data: student } = useDetails();
  // const firstSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'first')
  // const secondSemesterCourses = courses?.filter(course => (course.semester).toLowerCase() === 'second')
  const firstSemesterCourses = courses?.filter((course) =>
    course.semester.toLowerCase().includes("first")
  );
  const secondSemesterCourses = courses?.filter((course) =>
    course.semester.toLowerCase().includes("second")
  );

  const totalUnits = courses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 2rem 8rem;
  }
`;
  const printCourseReg = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Course Reg",
    onAfterPrint: () => console.log("Course Reg printed"),
    pageStyle: pageStyle,
  });

  useEffect(() => {
    const validated = async () => {
      try {
        const { data } = await api.get(`/courses/validated/first/100`);
        setValidation(data?.data[0]);
      } catch (error) {}
    };

    validated();
  }, [student]);

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/student-view?session=${session}`
      );
      setCourses(data);
      setLevel(data[0].registration_level);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRegisteredCourses();
  }, [session]);

  if (courses?.length === 0) {
    return (
      <Navigate
        to={{
          pathname: `/course-reg`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="card" ref={componentRef}>
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">College of Education, Warri</h3>
              </div>
              <h4 className="text-center">In Affiliation with</h4>
              <div className="school-title">
                <img src={logo2} alt="DSP" className="logo" />
                <h3 className="name">
                  Ignatius Ajuru University of Education,Port harcourt
                </h3>
              </div>
              <br />
              <div style={{ borderBottom: "2px solid #0E0E0E" }}></div>
              <h4 className="subheader">Course registration printout</h4>
            </div>
            <div className="p-5">
              <table id="mytable">
                <tbody>
                  <tr>
                    <th rowSpan={10}>
                      <img
                        alt="passport"
                        src={student?.photo}
                        style={{ width: "120px" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{student?.surname + " " + student?.othername}</td>
                  </tr>
                  <tr>
                    <th>Matriculation Number</th>
                    <td>{student?.matric_no}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{student?.department}</td>
                  </tr>
                  <tr>
                    <th>School/Faculty</th>
                    <td>{student?.faculty}</td>
                  </tr>
                  <tr>
                    <th>Level</th>
                    <td>{level || student?.level}</td>
                  </tr>
                  <tr>
                    <th>Programme</th>
                    <td>Masters in Education</td>
                  </tr>
                  <tr>
                    <th>Session</th>
                    <td>{session}</td>
                  </tr>
                  <tr>
                    <th>Entry</th>
                    <td>{student?.entry_session}</td>
                  </tr>
                </tbody>
              </table>
              <h4 className="d-flex justify-content-center subheader mt-3">
                Courses
              </h4>
              <table style={{ fontSize: "16px" }}>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Course</th>
                    <th>Unit</th>
                    <th>Semester</th>
                  </tr>
                </thead>
                <tbody>
                  {firstSemesterCourses?.length > 0 && (
                    <>
                      <h4 className="text-center">FIRST SEMESTER</h4>
                      {firstSemesterCourses?.map(
                        ({ id, code, semester, title, unit }) => (
                          <tr key={id}>
                            <td>{code}</td>
                            <td>{title}</td>
                            <td>{unit}</td>
                            <td>{semester}</td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                  {secondSemesterCourses?.length > 0 && (
                    <>
                      <div
                        style={{
                          pageBreakBefore:
                            firstSemesterCourses?.length > 0 ? "always" : "",
                        }}
                      ></div>
                      <h4 className="text-center">SECOND SEMESTER</h4>
                      {secondSemesterCourses?.map(
                        ({ id, code, semester, title, unit }) => (
                          <tr key={id}>
                            <td>{code}</td>
                            <td>{title}</td>
                            <td>{unit}</td>
                            <td>{semester}</td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </tbody>
              </table>

              <div
                style={{ padding: "0 10rem" }}
                className="mt-3 d-flex justify-content-between"
              >
                <h3>Total units</h3>
                <h3>{totalUnits}</h3>
              </div>
              <br />
              <br />
              <div className="d-flex">
                <h4 className="mr-1">I</h4>
                <div className="w-25 line2 mt-4 mr-1"></div>
                <h4>
                  do hereby declare that the above information
                  is true and correct
                </h4>
              </div>

              <table
                style={{
                  borderCollapse: "collapse",
                  border: "none",
                  fontSize: "20px",
                  marginTop: "4rem",
                }}
              >
                <thead style={{ border: "none" }}>
                  <td style={{ border: "none", width: "15em" }}></td>
                  <td style={{ border: "none", textAlign: "center" }}>Name</td>
                  <td
                    style={{
                      border: "none",
                      textAlign: "center",
                      width: "10em",
                    }}
                  >
                    Signature
                  </td>
                  <td style={{ border: "none", textAlign: "center" }}>Date</td>
                </thead>
                <tbody>
                  <tr>
                    <th style={{ border: "none", padding: "2rem" }}>Student</th>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                  </tr>
                  {/* <tr>
                    <th style={{ border: "none", padding: "2rem" }}>
                      Course Advisor
                    </th>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                  </tr>
                  <tr style={{ borderSpacing: "2rem" }}>
                    <th style={{ border: "none", padding: "2rem" }}>
                      Head of Department
                    </th>
                    <td style={{ border: "none", padding: "2rem" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                  </tr> */}
                  <tr>
                    <th style={{ border: "none", padding: "2rem" }}>
                      Director of Programme
                    </th>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="line2 mt-4"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <button className="btn dspg-danger" onClick={printCourseReg}>
              Print file
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegPrintout;
