import React from "react";
import usePayments from "../hooks/usePayments";

const StudentInfo = ({data}) => {
    const {payment} = usePayments()
    const schoolFee = payment?.school_fees_payment?.payment_status
  return (
    <>
      <p className="breadcrumb-item active">Student Information</p>

      <div class="form-group row">
        <div class="col-md-4">
          <label>Application Number</label>
          <input
            type="number"
            placeholder={data?.application_no}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Matriculation Number</label>
          <input
            type="text"
            placeholder={data?.matric_no}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Academic Session</label>
          <input
            type="text"
            placeholder={data?.entry_session}
            class="form-control"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <label>Faculty</label>
          <input
            type="number"
            placeholder={data?.faculty}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Department</label>
          <input
            type="text"
            placeholder={data?.department}
            class="form-control"
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
